<template>
  <div class="clearfix">
    <vs-row>
      <!-- 身分證或手機 tabs -->
      <vs-col>
        <!-- <vs-tabs alignment="fixed" v-model="identify_radio">
          身分證
          <vs-tab :label="$t('login.id')" @click="switchtab(0)">
          </vs-tab>
          手機
          <vs-tab :label="$t('login.phone')" @click="switchtab(1)">
          </vs-tab>
        </vs-tabs> -->
        <div class="flex mb-2">
          <div class="p-2 w-1/2 text-center cursor-pointer" :style="identify_radio == 0 ? 'border-bottom: 2px solid #003f8e; color: #003f8e;' : ''" @click="switchtab(0)">
            <span>{{ $t('login.id') }}</span>
          </div>
          <div class="p-2 w-1/2 text-center cursor-pointer" :style="identify_radio == 1 ? 'border-bottom: 2px solid #003f8e; color: #003f8e;' : ''" @click="switchtab(1)">
            <span>{{ $t('login.phone') }}</span>
          </div>
        </div>
      </vs-col>
      <!-- 身分證字號 input -->
      <vs-col class="cd-form-group" v-if="identify_radio==0">
        <vs-input
          name="account"
          icon-no-border
          icon-pack="feather"
          icon="icon-user"
          :placeholder="$t('login.id')"
          v-model="account"
          class="w-full" />
      </vs-col>
      <!-- 手機國碼 -->
      <vs-col class="cd-form-group" v-if="identify_radio==1">
        <v-select
          class="w-full cd-select"
          autocomplete
          :clearable="false"
          v-model="countryCode"
          :options="userinfo_country_code"
          label="label"
          :reduce="userinfo_country_code => `${userinfo_country_code.phoneCode}`" />
      </vs-col>
      <!-- 手機號碼 input -->
      <vs-col class="cd-form-group" v-if="identify_radio==1">
        <vs-input
          name="mobile"
          icon-no-border
          icon-pack="feather"
          icon="icon-phone"
          :placeholder="$t('login.mobile')"
          v-model="mobile"
          class="w-full" />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="space-between" vs-align="flex-end">
        <!-- 返回登入按鈕 -->
        <vs-button class="px-3 sm:px-4" type="border" to="/pages/login">{{$t('login.back')}}</vs-button>
        <!-- 送出按鈕 -->
        <vs-button class="px-6 sm:px-8" @click="forgetpasswordUserJWt" :disabled="!validateForm">{{$t('login.submit')}}</vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>
<style>
  .el-input .el-input__inner:focus, .el-input.is-focus .el-input__inner{
  border: 1px solid rgba(0,63,142);
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color:  rgba(0,63,142);
  }
</style>
<script>
import {countryCodes} from '@/api/countryCodes.js'
import vSelect from 'vue-select'
export default {
  data() {
    return {
      identify_radio:0,
      account: '',
      countryCode: '+886',
      mobile: '',
    }
  },
  components: {
    vSelect,
  },
  computed: {
    validateForm() {
      if(this.identify_radio==1){
        return this.countryCode != '' && this.mobile != '';
      }
      if(this.identify_radio==0){
        return this.account != '';
      }
    },
    userinfo_country_code() {
      countryCodes.forEach(element => {
        if(this.$i18n.locale=='tw'){
          element.label = element.countryName +' ('+element.phoneCode+')'
        }else if(this.$i18n.locale=='cn'){
          element.label = element.countryNameCN +' ('+element.phoneCode+')'
        }else if(this.$i18n.locale=='en'){
          element.label = element.countryCode +' ('+element.phoneCode+')'
        }
      });
      return countryCodes
    },
  },
  methods:{
    // 返回登入
    loginUser() {
      this.$router.push('/pages/login').catch(() => {})
    },
    // 修改密碼
    forgetpasswordUserJWt(){
      var _self = this
      // If form is not validated or user is already login return
      const payload ={}
      if (!_self.validateForm) return
      if(_self.identify_radio==0){
        payload['account'] = _self.account
      }
      
      if(_self.mobile.substring(0,1)!='0' &&_self.countryCode=='+886'){
        _self.mobile = '0'+_self.mobile
      }
      //判斷是否為電話
      if(_self.identify_radio==1){
        payload['account'] = _self.countryCode.split('+')[1]+_self.mobile
      }
      axios.post(process.env.VUE_APP_API_URL_HIS+'/api/forgetpassword', payload).then(res => {
        if(res.data.status=='OK'){
          _self.$router.push('/pages/login')
          _self.$vs.notify({
            color: 'grey',
            text: _self.$t('login.sendVerifyCode')+': '+res.data.message_error_phone,
            time:4500,
            position:'top-center'
          })
        }else if(res.data.status=='ERROR'){
          _self.$vs.notify({
            color: 'danger',
            text: res.data.message,
            time:4500,
            position:'top-center'
          })
        }
      })
    },
    // 手機國碼語系
    countryCodeTranslation(item){
      if(this.$i18n.locale=='tw'){
        return item.countryName +' ('+item.phoneCode+')'
      }else if(this.$i18n.locale=='cn'){
        return item.countryNameCN +' ('+item.phoneCode+')'
      }else if(this.$i18n.locale=='en'){
        return item.countryCode +' ('+item.phoneCode+')'
      }
    },
    // 切換 身分證/手機 tab
    switchtab(index){
      this.identify_radio = index
    },
  }
}
</script>