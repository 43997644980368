
<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4" style="max-width: 768px">
            <vx-card>
                <div>
                    <div class="vx-row no-gutter">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img :src="logo" alt="Curdr-login-04.png" class="cd-login-logo">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg sm:p-4 p-2">
                            <div class="vx-card__title">
                                <vs-row vs-align="center">
                                    <!-- 手機板logo圖 -->
                                    <vs-col class="layout--full-page" v-if="windowWidth<=768">
                                        <img :src="logo" alt="Curdr-login-04.png" class="cd-login-logo">
                                    </vs-col>
                                    <vs-col class="cd-form-group">
                                        <h3>{{$t('login.forgetPassword')}}</h3>
                                    </vs-col>
                                </vs-row>
                            </div>
                            <forget-password-jwt></forget-password-jwt>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import ForgetPasswordJwt from "./ForgetPasswordJwt"

export default {
    components: {
      ForgetPasswordJwt,
    },
    computed:{
        windowWidth()   { return this.$store.state.windowWidth },
        logo()          { return localStorage.getItem('login_logo') }
    },
    beforeRouteEnter (to, from, next) {
        if(localStorage.getItem("di_tokenId")) next({ path: "/" })
        else next()
    },
}
</script>